<template>
  <v-col class="px-0 py-0">
    <success-snackbar
      v-if="showSuccessSnackbar"
      snackbarText="Ваші дані успішно оновлені"
    />
    <div class="backgroundUser">
      <v-row no-gutters align="center">
        <div
          style="
            width: 100px;
            height: 100px;
            border-radius: 50%;
            background: #4b5262;
            display: grid;
            place-items: center;
          "
        >
          <v-icon size="86px" color="#FAFAFA">mdi-account</v-icon>
        </div>
        <v-col cols="3" style="margin-left: 20px">
          <p style="font-size: 32px; font-weight: 600; margin-bottom: 4px">
            {{ admin.first_name }} {{ admin.last_name }}
          </p>
          <span style="color: #4b5262">
            {{
              loggedUserPTrans.role == "admin" ? "Адміністратор" : "Менеджер"
            }}
          </span>
        </v-col>
        <v-row no-gutters align="center" justify="end">
          <v-btn class="logoutBtn" @click="logout">
            <v-row no-gutters align="center" justify="center">
              <div
                class="logoutIcon"
                style="background-color: #1b1b1b; margin-right: 8px"
              />
              Вийти
            </v-row>
          </v-btn>
        </v-row>
      </v-row>
    </div>
    <div class="sectionBackground">
      <p class="sectionTitle">Особисті дані</p>
      <div class="sectionLine" />
      <v-col cols="4" class="px-0 py-0">
        <v-text-field
          outlined
          dense
          height="48px"
          style="border-radius: 10px"
          background-color="#FFF"
          color="#E2E2E2"
          v-model="admin.first_name"
          label="Ім’я"
          placeholder="Введіть тут.."
          :error-messages="firstNameError"
        />
        <v-text-field
          outlined
          dense
          height="48px"
          style="border-radius: 10px"
          background-color="#FFF"
          color="#E2E2E2"
          v-model="admin.last_name"
          label="Прізвище"
          placeholder="Введіть тут.."
          :error-messages="lastNameError"
        />
        <v-text-field
          outlined
          dense
          height="48px"
          style="border-radius: 10px"
          background-color="#FFF"
          color="#E2E2E2"
          v-model="admin.phone_number"
          v-mask="'+###############'"
          label="Номер телефону (необов’язково)"
          placeholder="Введіть тут.."
        />
        <v-text-field
          outlined
          dense
          height="48px"
          style="border-radius: 10px"
          background-color="#FFF"
          color="#E2E2E2"
          v-model="admin.email"
          label="Email"
          placeholder="Введіть тут.."
          :error-messages="emailError"
        />
        <v-text-field
          outlined
          dense
          height="48px"
          style="border-radius: 10px"
          background-color="#FFF"
          color="#E2E2E2"
          v-model="admin.address"
          label="Адреса (необов’язково)"
          placeholder="Введіть тут.."
          autocomplete="address"
        />
      </v-col>
    </div>
    <div class="sectionBackground">
      <p class="sectionTitle">Пароль</p>
      <div class="sectionLine" />
      <v-row no-gutters align="start">
        <v-col cols="4" class="px-0 py-0">
          <v-text-field
            outlined
            dense
            color="#164B78"
            :placeholder="'type_here' | localize"
            label="Старий пароль"
            class="rounded-lg"
            height="48px"
            v-model="password.current_password"
            :type="showCurrentPassword ? 'text' : 'password'"
            :append-icon="showCurrentPassword ? 'mdi-eye' : 'mdi-eye-off'"
            :hide-details="!isChangePassword && $vuetify.breakpoint.xs"
            @click:append="showCurrentPassword = !showCurrentPassword"
            :error-messages="
              currentPasswordError.length
                ? currentPasswordError
                : serverPasswordError
            "
            @input="serverPasswordError = []"
          >
            <template v-slot:label>
              {{ "current_password_label" | localize
              }}<span class="requireColor">*</span>
            </template>
          </v-text-field>
          <v-expand-transition>
            <v-col class="px-0 py-0" v-if="isChangePassword">
              <v-tooltip bottom color="rgba(0, 0, 0, 0.8)" max-width="500px">
                <template v-slot:activator="{ on, attrs }">
                  <v-text-field
                    v-bind="attrs"
                    v-on="on"
                    outlined
                    dense
                    color="#164B78"
                    :placeholder="'type_here' | localize"
                    label="Новий пароль"
                    class="rounded-lg"
                    v-model="password.password"
                    height="48px"
                    :type="showNewPassword ? 'text' : 'password'"
                    :append-icon="showNewPassword ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showNewPassword = !showNewPassword"
                    :error-messages="passwordError"
                  >
                    <template v-slot:label>
                      {{ "new_password_label" | localize
                      }}<span class="requireColor">*</span>
                    </template>
                  </v-text-field>
                </template>
                {{ "password_hint" | localize }}
              </v-tooltip>
              <v-text-field
                outlined
                dense
                color="#164B78"
                :placeholder="'type_here' | localize"
                label="Повторіть пароль"
                class="rounded-lg"
                v-model="password.confirm_password"
                height="48px"
                :type="showConfirmPassword ? 'text' : 'password'"
                :append-icon="showConfirmPassword ? 'mdi-eye' : 'mdi-eye-off'"
                @click:append="showConfirmPassword = !showConfirmPassword"
                :error-messages="confirmPasswordError"
              >
                <template v-slot:label>
                  {{ "confirm_password_label" | localize
                  }}<span class="requireColor">*</span>
                </template>
              </v-text-field>
            </v-col>
          </v-expand-transition>
        </v-col>
        <submit-button
          style="width: 180px; height: 48px !important; margin-left: 20px"
          @click="isChangePassword = true"
          v-if="!isChangePassword && !$vuetify.breakpoint.smAndDown"
          >Змінити</submit-button
        >
      </v-row>
    </div>
    <submit-button
      style="
        font-size: 20px;
        font-weight: 700;
        height: 48px !important;
        margin-top: 40px;
      "
      @click="saveSetting"
      >Зберегти</submit-button
    >
    <v-row no-gutters justify="center" style="margin-top: 10px">
      <cancel-btn text="Скасувати" />
    </v-row>
  </v-col>
</template>

<script>
import { validationMixin } from "vuelidate";
import { email, required, minLength, sameAs } from "vuelidate/lib/validators";
import { mapActions, mapGetters } from "vuex";
import CancelBtn from "../UI/Buttons/cancelBtn.vue";
import submitButton from "../UI/Buttons/submitButton.vue";
import profileService from "@/requests/User/profileService";
import SuccessSnackbar from "../UI/Snackbars/successSnackbar.vue";
export default {
  components: { submitButton, CancelBtn, SuccessSnackbar },
  mixins: [validationMixin],
  data: () => ({
    admin: {
      phone_number: "",
      address: "",
    },
    password: {},
    showCurrentPassword: false,
    showNewPassword: false,
    showConfirmPassword: false,
    isChangePassword: false,
    showSuccessSnackbar: false,
    serverPasswordError: [],
  }),
  validations: {
    admin: {
      first_name: {
        required,
      },
      last_name: {
        required,
      },
      email: {
        required,
        email,
      },
    },
    password: {
      current_password: {
        required,
      },
      password: {
        required,
        minLength: minLength(8),
        validUppercase: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          return containsUppercase;
        },
        validLowercase: function (value) {
          const containsLowercase = /[a-z]/.test(value);
          return containsLowercase;
        },
        validNumber: function (value) {
          const containsNumber = /[0-9]/.test(value);
          return containsNumber;
        },
        validSpecial: function (value) {
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return containsSpecial;
        },
        valid: function (value) {
          const containsUppercase = /[A-Z]/.test(value);
          const containsLowercase = /[a-z]/.test(value);
          const containsNumber = /[0-9]/.test(value);
          const containsSpecial = /[#?!@$%^&*-]/.test(value);
          return (
            containsUppercase &&
            containsLowercase &&
            containsNumber &&
            containsSpecial &&
            minLength
          );
        },
      },
      confirm_password: {
        required,
        sameAsPassword: sameAs("password"),
      },
    },
  },
  mounted() {
    this.setAdmin();
  },
  methods: {
    ...mapActions(["updateInfoLogged"]),
    async saveSetting() {
      this.$v.admin.$touch();
      if (!this.$v.admin.$invalid) {
        let form = new FormData();
        form.append("first_name", this.admin.first_name);
        form.append("last_name", this.admin.last_name);
        form.append("email", this.admin.email);
        if (this.admin.phone_number !== "") {
          form.append("phone_number", this.admin.phone_number);
        }
        if (this.admin.address !== "") {
          form.append("address", this.admin.address);
        }
        await profileService.updateUserProfile(form).then((res) => {
          if (res.status == "Success") {
            this.updateInfoLogged({
              email: this.admin.email,
              first_name: this.admin.first_name,
              last_name: this.admin.last_name,
              phone_number: this.admin?.phone_number?.replace(/\D+/g, ""),
              role: this.loggedUserPTrans?.role,
              token: this.loggedUserPTrans?.token,
            });
            if (this.isChangePassword) {
              this.updateUserPassword();
            } else {
              this.showSuccessSnackbar = true;
            }
          }
        });
      }
    },
    async updateUserPassword() {
      this.$v.password.$touch();
      if (!this.$v.password.$invalid) {
        let password = new FormData();
        password.append("old_password", this.password.current_password);
        password.append("password", this.password.password);
        await profileService.updateUserPassword(password).then((res) => {
          if (res.status == "success") {
            this.showSuccessSnackbar = true;
            this.isChangePassword = false;
            this.password = {};
            this.$v.$reset();
          } else if (res.message == "Wrong password") {
            this.serverPasswordError.push(
              "Існуючий пароль введено не правильно"
            );
          }
        });
      }
    },
    setAdmin() {
      this.$set(this.admin, "first_name", this.loggedUserPTrans.first_name);
      this.$set(this.admin, "last_name", this.loggedUserPTrans.last_name);
      this.$set(this.admin, "email", this.loggedUserPTrans.email);
      this.$set(
        this.admin,
        "phone_number",
        this.loggedUserPTrans?.phone_number
      );
    },
    logout() {
      this.$store.commit("clearUserLogged");
      this.$router.push("/");
    },
  },
  computed: {
    ...mapGetters(["loggedUserPTrans"]),
    firstNameError() {
      const errors = [];
      let field = this.$v.admin.first_name;
      if (!field.$dirty) {
        return errors;
      } else if (!field.required) {
        errors.push("");
      }
      return errors;
    },
    lastNameError() {
      const errors = [];
      let field = this.$v.admin.last_name;
      if (!field.$dirty) {
        return errors;
      } else if (!field.required) {
        errors.push("");
      }
      return errors;
    },
    emailError() {
      const errors = [];
      let field = this.$v.admin.email;
      if (!field.$dirty) {
        return errors;
      } else if (!field.required) {
        errors.push("");
      }
      if (!field.email) {
        errors.push("");
      }
      return errors;
    },
    currentPasswordError() {
      const errors = [];
      if (!this.$v.password.current_password.$dirty) {
        return errors;
      }
      if (!this.$v.password.current_password.required) {
        errors.push("Обов'язкове поле");
        return errors;
      }
      return errors;
    },
    passwordError() {
      const errors = [];
      if (!this.$v.password.password.$dirty) {
        return errors;
      }
      if (!this.$v.password.password.required) {
        errors.push("Обов'язкове поле");
        return errors;
      }
      if (
        (!this.$v.password.password.valid &&
          this.$v.password.password.required) ||
        (!this.$v.password.password.minLength &&
          this.$v.password.password.required)
      ) {
        errors.push(
          "Пароль повинен містити мінімум 8 символів, серед яких хоч одну цифру, спецсимвол, велику та маленьку літери"
        );
        return errors;
      }
      return errors;
    },
    confirmPasswordError() {
      const errors = [];
      if (!this.$v.password.confirm_password.$dirty) {
        return errors;
      }
      if (!this.$v.password.confirm_password.sameAsPassword) {
        errors.push("Паролі не збігаються");
        return errors;
      }
      !this.$v.password.confirm_password.required &&
        errors.push("Обов'язкове поле");
      return errors;
    },
  },
};
</script>

<style scoped>
.backgroundUser {
  margin-top: 20px;
  padding: 20px;
  background: url("../../assets/img/userBackground.png") lightgray 50% / cover
    no-repeat;
  background-size: cover;
  height: 140px;
  width: 100%;
  border-radius: 10px;
  color: #1b1b1b;
  font-family: "MacPaw Fixel";
  font-size: 20px;
  font-style: normal;
  font-weight: 400;
}
.logoutBtn {
  border-radius: 10px;
  background: rgba(223, 58, 58, 0.2) !important;
  text-transform: none !important;
  color: #1b1b1b !important;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  height: 48px !important;
  width: 180px !important;
}
</style>